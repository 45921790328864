import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

export const Container = styled.div``;

export const BreadcrumbContent = styled(ReactMarkdown)`
  ol {
    margin: 0;
    display: flex;
    list-style: none;
  }

  li {
    margin-bottom: 0;
    margin-right: 0.5rem;
    width: fit-content;
    color: #8f9194;
    font-size: 0.875rem;
    color: ${({ lightMode }) => lightMode && 'white'};

    > a {
      color: #45a7df;
    }

    > a:hover {
      text-decoration: underline;
    }
  }
`;
