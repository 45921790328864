import React, { useState, useEffect } from 'react';

import { Breadcrumb } from 'components/CmsComponents/FeaturedMarketing/Breadcrumb';
import { Highlight } from 'components/CmsComponents/FeaturedMarketing/Highlight';
import { NewnessBanner } from 'components/CmsComponents/FeaturedMarketing/NewnessBanner';
import { CustomFragment } from 'components/CmsComponents/CustomFragment';
import { formatClassName } from 'utils/formatClassName';

import BlobPink from 'images/blobs/Slime_Pink.webp';
import BlobPurple from 'images/blobs/Slime_Purple.webp';
import BlobMarineBlue from 'images/blobs/Slime_MarineBlue.webp';

import {
  Container,
  ImageBlobPink,
  ImageBlobPurple,
  ImageBlobMarineBlue,
  Content,
  WrapBreadcrumb,
  WrapHighlight,
  Box,
  Information,
  Title,
  WrapImage,
  Image,
  WrapBlobs,
  BlobsReference,
} from './style';
import BreakingNews from './BreakingNews';
import { Maps } from './Maps';

function FeaturedMarketing({ content }) {
  const [isMobile, setIsMobile] = useState(false);
  const hasMultipleImages = content?.images?.length > 1;

  const formattedClassName = formatClassName(content.type);
  const isBgImage = formattedClassName.includes('bgImage');
  const hasBlobs = formattedClassName.includes('FeaturedMarketingBlobs');
  const highlightHeightContent = `${
    content?.highlight ? content?.highlight?.length / 7.74 / 16 : 0
  }rem`;

  const image = hasMultipleImages
    ? isMobile
      ? content?.images?.[1]
      : content?.images?.[0]
    : content?.images?.[0];

  const updateWindowSize = () => {
    setIsMobile(window.innerWidth < 1025);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      updateWindowSize();
    }
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateWindowSize);
      return () => window.removeEventListener('resize', updateWindowSize);
    }
  }, []);

  return (
    <Container
      bgColor={content?.backgroundColor?.hex ?? 'transparent'}
      backgroundInitialColor={content?.backgroundInitialColor?.hex}
      backgroundFinalColor={content?.backgroundFinalColor?.hex}
      className={formattedClassName}
      data-testid="container"
    >
      <Content
        bgImage={isBgImage ? image?.url ?? '' : ''}
        bgHeight={isBgImage ? image?.height ?? 'auto' : ''}
        hasBorderBottom={
          !content?.backgroundColor?.hex && !content?.backgroundFinalColor?.hex
        }
        className={formattedClassName}
        hasHighlight={content?.activeHighlight ?? false}
      >
        <WrapBreadcrumb>
          <Breadcrumb
            markdownContent={content?.breadcrumb ?? ''}
            lightMode={
              !!formattedClassName
                .toString()
                .includes('FeaturedMarketingModelo13')
            }
          />
        </WrapBreadcrumb>
        {content?.breaknews && content?.activeBreaknews && (
          <BreakingNews
            className={formattedClassName}
            markdownContent={content?.breaknews ?? ''}
          />
        )}
        {content?.activeHighlight && (
          <WrapHighlight>
            <Highlight markdownContent={content?.highlight ?? ''} />
          </WrapHighlight>
        )}
        {content?.newnessBannerActive && (
          <NewnessBanner
            markdownContent={content?.newnessBanner ?? ''}
            className={formattedClassName}
          />
        )}
        <Box
          className={formattedClassName}
          hasHighlight={content?.activeHighlight ?? false}
          highlightHeightContent={highlightHeightContent}
        >
          <Information className={formattedClassName}>
            <Title className={formattedClassName}>
              {content?.title?.replaceAll('[break]', '\n') ?? ''}
            </Title>
            {content?.map && <Maps markdownContent={content.map} />}
            {content?.fragments?.map(fragment => {
              return (
                <CustomFragment
                  key={fragment.id}
                  className={formatClassName(fragment.type)}
                  children={fragment?.markdown?.replaceAll('[break]', '\n')}
                  debugName={fragment.name}
                  singletexts={fragment.singletexts}
                />
              );
            })}
          </Information>
          {!isBgImage && (
            <WrapImage className={formattedClassName}>
              {hasBlobs && (
                <WrapBlobs>
                  <BlobsReference>
                    <ImageBlobPink
                      className={formattedClassName}
                      bgUrl={BlobPink}
                      loading="lazy"
                    />
                    <ImageBlobPurple
                      className={formattedClassName}
                      bgUrl={BlobPurple}
                      loading="lazy"
                    />
                    <ImageBlobMarineBlue
                      className={formattedClassName}
                      bgUrl={BlobMarineBlue}
                      loading="lazy"
                    />
                  </BlobsReference>
                </WrapBlobs>
              )}

              {image?.url && (
                <Image
                  maxWidth={image?.width}
                  src={image?.url}
                  alt="Destaque Marketing"
                  className={formattedClassName}
                  loading="lazy"
                />
              )}
            </WrapImage>
          )}
        </Box>
      </Content>
    </Container>
  );
}

export { FeaturedMarketing };
