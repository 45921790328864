import React from 'react';
import { Link } from 'gatsby';

import { Container } from './style';

const NewnessBanner = ({ markdownContent, className }) => {
  const LinkRender = ({ href, children }) => {
    const isInternalLink = href.startsWith('/');

    if (isInternalLink) {
      return <Link to={href}>{children}</Link>;
    }

    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  };

  return (
    <Container
      className={className}
      renderers={{
        link: LinkRender,
      }}
    >
      {markdownContent.markdown}
    </Container>
  );
};

export { NewnessBanner };
