import styled from 'styled-components';

import { device } from 'components/device';

export const Maps = styled.div`
  display: none;
  width: 37.5rem;
  height: 23.75rem;
  background-image: url(${props => props.mapImage && props.mapImage});
  background-position: center;
  margin-top: 3rem;

  @media ${device.laptop} {
    display: none;
  }
`;

export const MapsMobile = styled.div`
  display: flex;

  @media ${device.laptop} {
    display: flex;
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
    width: 100%;
    height: 18.75rem;
    background-image: url(${({ mapImage }) => mapImage && mapImage});
    background-position: center;
    background-size: cover;
  }

  @media ${device.mobile} {
    background-image: unset;
    height: 14.6875rem;
  }
`;
