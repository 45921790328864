import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background: #ecf6fc;
  border-radius: 0.75rem;
  padding: 0.875rem 1.25rem;
`;

export const HighlightContent = styled(ReactMarkdown)`
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: normal;
  color: #231f20;

  strong {
    font-weight: 600;
  }

  a {
    font-weight: 600;
    color: #45a7df;
  }
`;
