import styled, { css } from 'styled-components';

import { device } from 'components/device';

export const Container = styled.section`
  width: 100%;
  background: ${({ bgColor, backgroundInitialColor, backgroundFinalColor }) =>
    bgColor !== undefined && !backgroundInitialColor && !backgroundFinalColor
      ? bgColor
      : backgroundInitialColor &&
        backgroundFinalColor &&
        `linear-gradient(135deg, ${backgroundInitialColor} 0%, ${backgroundFinalColor} 100%)`};

  @media ${device.laptop} {
    padding: 0 1.25rem;
    &.noPaddingFeatured {
      padding: 0;
    }
  }

  &.mb20 {
    padding-bottom: 1.25rem;
  }

  &.mb30 {
    padding-bottom: 1.875rem;

    @media ${device.laptop} {
      padding-bottom: 1.25rem;
    }
  }

  &.mb50 {
    padding-bottom: 3.125rem;

    @media ${device.laptop} {
      padding-bottom: 1.875rem;
    }
  }

  &.FeaturedMarketingModelo13 {
    p {
      font-size: 24px;
      font-weight: 700;
      line-height: 31.92px;

      @media ${device.laptop} {
        font-size: 16px;
        line-height: 25.92px;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 73.125rem;
  min-height: ${({ bgHeight }) => (bgHeight ? bgHeight + 'px' : 'auto')};
  margin: 0 auto;

  background-image: ${({ bgImage }) => (bgImage ? `url(${bgImage})` : 'unset')};
  background-repeat: no-repeat;
  background-position: right top;

  ${({ hasBorderBottom }) =>
    hasBorderBottom &&
    css`
      border-bottom: 1px solid #c6c8cc;
    `}

  @media ${device.laptop} {
    max-width: unset;

    ${({ hasHighlight }) =>
      hasHighlight &&
      css`
        flex-direction: column-reverse;
      `}
  }

  &.FeaturedMarketingModelo6 {
    @media ${device.laptop} {
      border: none;
    }
  }

  &.maxHeight480 {
    max-height: 30rem;

    @media ${device.laptop} {
      max-height: unset;
    }
  }
`;

export const WrapBreadcrumb = styled.div`
  width: 100%;
  position: absolute;
  top: 1.875rem;
  z-index: 2;

  @media ${device.laptop} {
    display: none;
  }
`;

export const WrapHighlight = styled.div`
  width: 100%;
  position: absolute;
  top: 6.25rem;
  z-index: 2;

  @media ${device.laptop} {
    position: unset;
    top: unset;
    z-index: unset;

    margin-bottom: 1.25rem;
  }
`;

export const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1.25rem;

  ${({ hasHighlight, highlightHeightContent }) =>
    hasHighlight &&
    css`
      padding-top: calc(5.125rem + ${highlightHeightContent});
    `}

  @media ${device.laptop} {
    align-items: center;
  }

  @media ${device.mobile} {
    align-items: unset;
  }

  &.FeaturedMarketingModelo1 {
    height: 100%;
    min-height: 35.625rem;

    @media ${device.laptop} {
      height: auto;
      min-height: unset;
      flex-direction: column-reverse;
      padding-top: 2rem;
    }
  }

  &.FeaturedMarketingModelo2 {
    height: 100%;
    min-height: 26.25rem;

    @media ${device.laptop} {
      height: auto;
      min-height: unset;
      flex-direction: column-reverse;
      padding-top: 2rem;
    }
  }

  &.FeaturedMarketingModelo3 {
    min-height: 26.25rem;
    @media ${device.laptop} {
      padding-top: 0;
      padding-bottom: 1rem;
    }
  }

  &.FeaturedMarketingModelo4 {
    height: auto;

    @media ${device.laptop} {
      flex-direction: column-reverse;
      padding-top: 2rem;
    }
  }

  &.FeaturedMarketingModelo5 {
    height: 100%;
    min-height: 26.25rem;
    max-height: 33rem;

    @media ${device.laptop} {
      height: auto;
      min-height: unset;
      max-height: unset;
      flex-direction: column-reverse;
      padding-top: -0rem;
    }
  }

  &.FeaturedMarketingModelo6 {
    height: 100%;
    min-height: 35.625rem;
    margin-top: 5.6rem;

    @media ${device.laptop} {
      height: auto;
      min-height: unset;
      flex-direction: column-reverse;
      padding-top: 0rem;
      margin-top: 1.563rem;
    }
  }

  &.FeaturedMarketingModelo7 {
    height: 100%;
    min-height: 35.625rem;
    margin-top: 0;

    @media ${device.laptop} {
      height: auto;
      min-height: unset;
      flex-direction: row;
      padding-top: 0rem;
      margin-top: 1.563rem;
    }

    @media ${device.mobile} {
      gap: unset;
    }
  }

  &.FeaturedMarketingModelo8 {
    height: 100%;
    min-height: 35.625rem;
    margin-top: 0;

    @media ${device.laptop} {
      height: auto;
      min-height: unset;
      flex-direction: row;
      padding-top: 0rem;
      margin-top: 1.563rem;
    }

    @media ${device.mobile} {
      gap: unset;
    }
  }

  &.FeaturedMarketingModelo9 {
    height: 100%;
    max-height: 35.625rem;
    margin-top: 0;

    height: 100%;

    @media ${device.laptop} {
      height: auto;
      min-height: unset;
      flex-direction: row;
      padding-top: 0rem;
      margin-top: 1.563rem;
      margin-bottom: 1rem;
    }

    @media ${device.mobile} {
      gap: unset;
    }
  }

  &.FeaturedMarketingModelo10 {
    height: 100%;
    min-height: 35.625rem;
    margin-top: 0;

    @media ${device.laptop} {
      height: auto;
      min-height: unset;
      flex-direction: row;
      padding-top: 0rem;
      margin-top: 1.563rem;
    }

    @media ${device.mobile} {
      gap: unset;
    }
  }

  &.FeaturedMarketingModelo11 {
    height: 100%;
    max-height: 30.625rem;
    margin-top: 0;

    height: 100%;

    @media ${device.laptop} {
      height: auto;
      min-height: unset;
      flex-direction: row;
      padding-top: 0rem;
      margin-top: 1.563rem;
      margin-bottom: 1rem;
    }

    @media ${device.mobile} {
      gap: unset;
    }
  }

  &.customizationOfEmergencyService {
    min-height: auto;
  }

  &.mt48 {
    margin-top: 5.69rem;
  }

  &.MinHeightUnset {
    min-height: unset !important;
  }

  &.TitleMobileUnits {
    @media ${device.mobile} {
      flex-direction: column-reverse;
    }
  }
  &.FeaturedMarketingModelo12 {
    height: 100%;
    min-height: 33.25rem;
    max-height: 33rem;

    @media ${device.laptop} {
      height: auto;
      min-height: unset;
      max-height: unset;
      flex-direction: column-reverse;
      margin-top: 20px;
    }
  }

  &.FeaturedMarketingModelo13 {
    height: 100%;
    min-height: 35.625rem;
    color: white;

    @media ${device.laptop} {
      height: auto;
      min-height: unset;
      flex-direction: column-reverse;
      padding-top: 2rem;
    }
  }
`;

export const Information = styled.div`
  width: 100%;
  margin: 6rem 0 1.875rem;

  @media ${device.laptop} {
    max-width: 50%;
  }

  @media ${device.mobile} {
    max-width: unset;

    margin: 8.25rem 0 1.25rem;
  }

  &.defaultLayoutMedicalSpecialties {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &.defaultLayoutMedicalSpecialtiesType2 {
    display: flex;
    flex-direction: column;
    justify-content: start;
  }

  &.FeaturedMarketingModelo1 {
    max-width: 25rem;
    &.WidthTitle29rem {
      max-width: 29rem;
    }

    @media ${device.laptop} {
      max-width: unset;
      margin: 0 0 1.25rem;
    }
  }

  &.FeaturedMarketingModelo2 {
    max-width: unset;

    @media ${device.laptop} {
      max-width: unset;
      margin: 0 0 1.25rem;
    }
  }

  &.FeaturedMarketingModelo3 {
    max-width: 26rem;
  }

  &.FeaturedMarketingModelo4 {
    max-width: unset;
    margin-bottom: 3.75rem;

    @media ${device.laptop} {
      max-width: unset;
      margin: 0 0 1.25rem;
    }
  }

  &.FeaturedMarketingModelo5 {
    max-width: unset;
    margin: 5.4rem 0 1.875rem;

    @media ${device.laptop} {
      max-width: unset;
      margin: 0 0 0.25rem;
    }
  }

  &.FeaturedMarketingModelo6 {
    max-width: 29rem;

    textarea {
      display: none;
    }

    @media ${device.laptop} {
      max-width: unset;
      margin: 0 0 1.25rem;
      display: flex;
      flex-direction: column;
    }
  }

  &.FeaturedMarketingModelo7 {
    max-width: unset;
    margin-bottom: 0;

    textarea {
      display: none;
    }

    @media ${device.laptop} {
      max-width: unset;
      margin: 0 0 1.875rem;
    }

    @media ${device.mobile} {
      margin: 1.875rem 0 1.875rem;
    }
  }

  &.FeaturedMarketingModelo8 {
    max-width: unset;

    textarea {
      display: none;
    }

    @media ${device.laptop} {
      max-width: unset;
      margin: 0 0 1.875rem;
    }

    @media ${device.mobile} {
      margin: 1.875rem 0 1.875rem;
    }
  }

  &.FeaturedMarketingModelo9 {
    max-width: unset;

    textarea {
      display: none;
    }

    @media ${device.laptop} {
      max-width: unset;
      margin: 0 0 1.875rem;
    }

    @media ${device.mobile} {
      margin: 1.875rem 0 1.875rem;
    }
  }

  &.FeaturedMarketingModelo10 {
    max-width: unset;

    textarea {
      display: none;
    }

    @media ${device.laptop} {
      max-width: unset;
      margin: 0 0 1.875rem;
    }

    @media ${device.mobile} {
      margin: 1.875rem 0 1rem 0;
    }
  }

  &.FeaturedMarketingModelo11 {
    max-width: unset;

    textarea {
      display: none;
    }

    @media ${device.laptop} {
      max-width: unset;
      margin: 0 0 1.875rem;
    }

    @media ${device.mobile} {
      margin: 1.875rem 0 1.875rem;
    }
  }

  &.FeaturedMarketingModelo12 {
    max-width: unset;

    @media ${device.laptop} {
      max-width: unset;
      margin: 0 0 1.25rem;
    }

    @media ${device.mobile} {
      max-width: unset;

      margin: 0.25rem 0 1.25rem;
    }
  }

  &.FeaturedMarketingModelo13 {
    max-width: 25rem;
    &.WidthTitle29rem {
      max-width: 29rem;
    }

    @media ${device.laptop} {
      max-width: unset;
      margin: 0 0 1.25rem;
    }
  }

  &.customizationOfEmergencyService {
    margin-top: 5.0625rem;

    @media ${device.laptop} {
      margin-top: unset;
    }

    @media ${device.mobile} {
      margin-top: 1.875rem;
    }
  }

  &.leftSideWidth476px {
    width: 100%;
    max-width: 29.75rem;

    @media ${device.laptop} {
      max-width: unset;
    }
  }

  &.maxWidthInfoUnset {
    max-width: unset;
  }
`;

export const Title = styled.h2`
  width: 100%;
  max-width: 29.375rem;
  font-size: 3.375rem;
  line-height: 3.75rem;
  font-weight: 700;
  color: #231f20;
  margin: 0;
  margin-bottom: 1.875rem;
  white-space: pre-line;

  @media ${device.laptop} {
    max-width: 50%;
    font-size: 1.5rem;
    line-height: 1.875rem;
    margin-bottom: 1.25rem;
  }

  &.lineBreakTitle {
    word-spacing: 100vw;
  }

  &.FeaturedMarketingModelo1 {
    @media ${device.laptop} {
      max-width: unset;
    }
  }

  &.FeaturedMarketingModelo2 {
    @media ${device.laptop} {
      max-width: unset;
    }
  }

  &.FeaturedMarketingModelo3 {
    @media ${device.laptop} {
      margin-bottom: 0.625rem;
    }
  }

  &.FeaturedMarketingModelo4 {
    max-width: unset;

    @media ${device.laptop} {
      max-width: unset;
    }
  }

  &.FeaturedMarketingModelo5 {
    @media ${device.laptop} {
      max-width: unset;
    }
  }

  &.FeaturedMarketingModelo6 {
    @media ${device.laptop} {
      max-width: 19rem;
    }
  }

  &.FeaturedMarketingModelo7 {
    max-width: unset;
    @media ${device.laptop} {
      max-width: 10rem;
    }
  }

  &.TitleDesk80 {
    width: 80%;
  }

  &.TitleDesk90 {
    width: 90%;
  }

  &.TitleDesk103 {
    width: 103%;
  }

  &.TitleDesk120 {
    width: 120%;

    @media ${device.laptop} {
      width: 100%;
    }
  }

  @media ${device.mobile} {
    &.TitleMobile20 {
      width: 20%;
    }

    &.TitleMobile30 {
      width: 30%;
    }
    &.TitleMobile40 {
      width: 40%;
    }

    &.TitleMobile50 {
      width: 50%;
    }
    &.TitleMobile60 {
      width: 60%;
    }

    &.TitleMobile70 {
      width: 70%;
    }
    &.TitleMobile80 {
      width: 80%;
    }

    &.TitleMobile90 {
      width: 90%;
    }

    &.TitleMobile100 {
      width: 100%;
    }
  }

  &.FeaturedMarketingModelo8 {
    margin-top: 1rem;
    max-width: unset;
    @media ${device.laptop} {
      max-width: 10rem;
      margin-top: 0rem;
    }
  }

  &.FeaturedMarketingModelo9 {
    max-width: unset;
    @media ${device.laptop} {
      max-width: 10rem;
    }
  }

  &.FeaturedMarketingModelo11 {
    max-width: unset;
    @media ${device.laptop} {
      max-width: 10rem;
    }
  }

  &.FeaturedMarketingModelo10 {
    max-width: unset;
    @media ${device.laptop} {
      max-width: 10rem;
    }
  }

  &.FeaturedMarketingModelo9 {
    max-width: unset;
    @media ${device.laptop} {
      max-width: 10rem;
    }
  }

  &.FeaturedMarketingModelo11 {
    max-width: unset;
    @media ${device.laptop} {
      max-width: 10rem;
    }
  }

  &.FeaturedMarketingModelo10 {
    max-width: unset;
    @media ${device.laptop} {
      max-width: 10rem;
    }
  }

  &.leftSideWidth476px {
    max-width: unset;
  }

  &.TitleMobileUnits {
    @media ${device.laptop} {
      max-width: 16rem;
      margin: 0;
      margin-bottom: 1.875rem;
    }
  }

  &.FeaturedMarketingModelo12 {
    max-width: unset;

    @media ${device.laptop} {
      max-width: 33%;
      font-size: 1.5rem;
      line-height: 1.875rem;
      margin-bottom: 1.25rem;
    }

    @media ${device.tablet} {
      max-width: 38%;
      font-size: 1.5rem;
      line-height: 1.875rem;
      margin-bottom: 1.25rem;
    }

    @media ${device.mobile} {
      max-width: 60%;
    }
    @media ${device.mobile500} {
      max-width: 100%;
    }

    @media ${device.mobile375} {
      width: 90%;
    }

    @media ${device.mobile360} {
      width: 100%;
    }
  }

  &.FeaturedMarketingModelo13 {
    color: white;
    font-size: 64px;

    @media ${device.laptop} {
      max-width: unset;
      line-height: auto;
      font-size: 32px;
    }
  }
`;

export const WrapImage = styled.div`
  position: relative;
  width: 100%;
  max-width: 35.625rem;
  height: auto;
  min-height: 14rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &.defaultLayoutMedicalSpecialties {
    margin-top: 8rem;
    margin-bottom: 8rem;

    @media ${device.laptop} {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &.defaultLayoutMedicalSpecialtiesType2 {
    margin-top: 6rem;
    margin-bottom: 6rem;

    @media ${device.laptop} {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &.FeaturedMarketingModelo2 {
    margin-top: 3.75rem;

    @media ${device.mobile} {
      margin-top: unset;
      padding: 0 2.375rem;
    }

    @media ${device.mobile320} {
      margin-top: unset;
      padding: unset;
    }
  }

  &.FeaturedMarketingModelo3 {
    margin-bottom: -5rem;
  }

  &.FeaturedMarketingModelo4 {
    margin-top: auto;
    margin-bottom: 20px;
  }

  &.FeaturedMarketingModelo5 {
    margin-top: 1.75rem;

    @media ${device.mobile} {
      margin-top: unset;
      padding: ${props => (props.isPadding ? props.isPadding : '0 2.375rem')};
    }

    @media ${device.mobile320} {
      margin-top: -0.25rem;
      padding: unset;
    }
  }

  &.FeaturedMarketingModelo6 {
    @media ${device.laptop} {
      display: none;
    }
  }

  &.FeaturedMarketingModelo7 {
    @media ${device.laptop} {
      height: auto;
      justify-content: flex-end;

      img {
        max-width: 25rem;
        height: auto;
        top: 0;
      }
    }

    @media ${device.tablet} {
      img {
        max-width: 17rem;
      }
    }

    @media ${device.mobile} {
      position: absolute;
      top: 0;
      right: -0.625rem;
      min-height: unset;

      img {
        max-width: 12rem;
        position: absolute;
      }
    }

    @media ${device.mobile360} {
      right: -1.25rem;
    }

    @media ${device.mobile320} {
      top: 1.5625rem;
    }

    @media ${device.mobile320} {
      img {
        max-width: 9.313rem;
      }
    }
  }

  &.FeaturedMarketingModelo9 {
    margin-top: 1.875rem;

    @media ${device.mobile} {
      margin-top: unset;
      padding: 0 2.375rem;
    }

    @media ${device.mobile320} {
      margin-top: unset;
      padding: unset;
    }

    @media ${device.laptop} {
      height: 100%;
      justify-content: flex-end;
      margin-top: 0rem;

      img {
        max-width: 25rem;
        height: auto;
        top: 0;
      }
    }

    @media ${device.tablet} {
      img {
        max-width: 17rem;
      }
    }

    @media ${device.mobile} {
      position: absolute;
      top: 1rem;
      right: -3.625rem;

      img {
        max-width: 12.8rem;
        position: absolute;
      }
    }

    @media ${device.mobile360} {
      right: -1.25rem;
    }

    @media ${device.mobile320} {
      top: 1.5625rem;
    }

    @media ${device.mobile320} {
      img {
        max-width: 9.313rem;
      }
    }
  }

  &.FeaturedMarketingModelo8 {
    margin-top: 3rem;

    @media ${device.laptop} {
      height: 100%;
      justify-content: flex-end;
      margin-top: 0rem;
      z-index: -1;

      img {
        max-width: 25rem;
        height: auto;
        top: 0;
      }
    }

    @media ${device.tablet} {
      img {
        max-width: 17rem;
      }
    }

    @media ${device.mobile} {
      position: absolute;
      top: 0;
      right: -0.625rem;

      img {
        max-width: 12.313rem;
        position: absolute;
      }
    }

    @media ${device.mobile360} {
      right: -1.25rem;
    }

    @media ${device.mobile320} {
      top: 1.5625rem;
    }

    @media ${device.mobile320} {
      img {
        max-width: 9.313rem;
      }
    }
  }

  &.FeaturedMarketingModelo10 {
    @media ${device.laptop} {
      height: 100%;
      justify-content: flex-end;

      img {
        max-width: 25rem;
        height: auto;
        top: 0;
      }
    }

    @media ${device.tablet} {
      img {
        max-width: 17rem;
      }
    }

    @media ${device.mobile} {
      position: absolute;
      top: 0;
      right: -0.625rem;
      z-index: -1;

      img {
        max-width: 12.313rem;
        position: absolute;
      }
    }

    @media ${device.mobile360} {
      right: -1.25rem;
    }

    @media ${device.mobile320} {
      top: 1.5625rem;
    }

    @media ${device.mobile320} {
      img {
        max-width: 9.313rem;
      }
    }
  }

  &.FeaturedMarketingModelo11 {
    margin-top: 3rem;

    @media ${device.mobile} {
      margin-top: unset;
      padding: 0 2.375rem;
    }

    @media ${device.mobile320} {
      margin-top: unset;
      padding: unset;
    }

    @media ${device.laptop} {
      height: 100%;
      justify-content: flex-end;
      margin-top: 0rem;

      img {
        max-width: 25rem;
        height: auto;
        top: 0;
      }
    }

    @media ${device.tablet} {
      img {
        max-width: 17rem;
      }
    }

    @media ${device.mobile} {
      position: absolute;
      top: 1rem;
      right: -2.625rem;

      img {
        max-width: 10rem;
        position: absolute;
      }
    }

    @media ${device.mobile360} {
      right: -1.25rem;

      img {
        top: -1rem;
      }
    }

    @media ${device.mobile320} {
      top: 1.5625rem;
    }

    @media ${device.mobile320} {
      img {
        max-width: 9.313rem;
      }
    }
  }

  &.top20 {
    @media ${device.mobile} {
      top: 1.25rem;
    }
    @media ${device.mobile320} {
      top: 2.5rem;
    }
  }

  &.top20 {
    @media ${device.mobile} {
      top: 1.25rem;
    }
    @media ${device.mobile320} {
      top: 2.5rem;
    }
  }

  &.WrapImgTop {
    @media ${device.mobile} {
      top: -5rem;
    }

    @media ${device.mobile320} {
      top: -3rem;
    }
  }

  &.imgMobileMaxWidh160 {
    @media ${device.mobile} {
      max-width: 10rem;
    }

    @media ${device.mobile320} {
      max-width: 8rem;
    }
  }

  &.TitleMobileUnits {
    @media ${device.mobile} {
      position: unset;
      top: 0;
      right: 0;
    }
  }
`;

export const Image = styled.img`
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth / 16 + 'rem' : 'unset')};
  height: auto;

  object-fit: cover;
  object-position: center;

  &.FeaturedMarketingModelo11 {
    width: 600px;
    height: 443px;
    object-position: 0% -2%;

    @media ${device.mobile} {
      width: 150px;
      height: 160px !important;
      object-fit: cover;
      object-position: -170% 0%;
    }
  }

  &.TitleMobileUnits {
    @media ${device.mobile} {
      position: unset !important;
      max-width: unset !important;
      width: 100%;
      height: auto !important;
    }
  }
`;

export const WrapBlobs = styled.div`
  position: absolute;
  width: 1rem;
  margin: 0 auto;
  z-index: -1;
`;

export const BlobsReference = styled.div`
  position: relative;
  width: 100%;
`;

export const ImageBlobPink = styled.div`
  display: none;

  background-image: ${({ bgUrl }) => `url(${bgUrl})`};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  &.defaultLayoutMedicalSpecialties {
    display: block;
    position: absolute;
    top: 2.5rem;
    left: -16.5rem;
    width: 15.875rem;
    height: 12.5rem;
    transform: rotate(189deg);
    z-index: -1;

    @media ${device.laptop} {
      top: 6.625rem;
      left: -15rem;
      width: 8.375rem;
      height: 6.625rem;
    }

    @media ${device.mobile} {
      top: 1.875rem;
      left: -8.4375rem;
      width: 8.375rem;
      height: 6.625rem;
    }

    @media (max-width: 414px) and (min-width: 411px) {
      top: 2.625rem;
      left: -10.125rem;
    }

    @media ${device.mobile320} {
      top: 0.625rem;
      left: -7.125rem;
    }
  }

  &.defaultLayoutMedicalSpecialtiesType2 {
    display: block;
    position: absolute;
    top: 2.5rem;
    left: -16.5rem;
    width: 15.875rem;
    height: 12.5rem;
    transform: rotate(189deg);
    z-index: -1;

    @media ${device.laptop} {
      top: 6.625rem;
      left: -15rem;
      width: 8.375rem;
      height: 6.625rem;
    }

    @media ${device.mobile} {
      top: 1.875rem;
      left: -8.4375rem;
      width: 8.375rem;
      height: 6.625rem;
    }

    @media (max-width: 414px) and (min-width: 411px) {
      top: 2.625rem;
      left: -10.125rem;
    }

    @media ${device.mobile320} {
      top: 0.625rem;
      left: -7.125rem;
    }
  }

  &.BlobsModelo1 {
    display: block;
    position: absolute;
    top: 1rem;
    left: -19.5rem;
    width: 15.875rem;
    height: 12.5rem;
    transform: rotate(-124.78deg);
    z-index: -1;

    @media ${device.laptop} {
      top: 5.625rem;
      left: -18rem;
      width: 8.375rem;
      height: 6.625rem;
    }

    @media ${device.mobile} {
      top: 0.75rem;
      left: -10.75rem;
      width: 8.375rem;
      height: 6.625rem;
    }

    @media ${device.mobile425} {
      top: 1.75rem;
      left: -11rem;
      width: 8.375rem;
      height: 6.625rem;
    }

    @media ${device.mobile375} {
      top: 0.75rem;
      left: -10.75rem;
      width: 8.375rem;
      height: 6.625rem;
    }

    @media ${device.mobile360} {
      top: 0.25rem;
      left: -9.25rem;
      width: 8.375rem;
      height: 6.625rem;
    }
  }

  &.BlobsModelo2 {
    display: block;
    position: absolute;
    top: -12.375rem;
    right: -12rem;
    width: 17.375rem;
    height: 13.75rem;
    transform: rotate(0deg);

    @media ${device.laptop} {
      top: -11.75rem;
      right: -11.75rem;
      width: 12.375rem;
      height: 9.875rem;
    }

    @media ${device.mobile} {
      top: -8.75rem;
      right: -8.75rem;
      width: 12.375rem;
      height: 9.875rem;
    }
  }

  &.BlobsModelo3 {
    display: block;
    position: absolute;
    top: -12.375rem;
    right: -12rem;
    width: 17.375rem;
    height: 13.75rem;
    transform: rotate(0deg);

    @media ${device.laptop} {
      top: -11.75rem;
      right: -11.75rem;
      width: 12.375rem;
      height: 9.875rem;
    }

    @media ${device.mobile} {
      top: -8.75rem;
      right: -8.75rem;
      width: 12.375rem;
      height: 9.875rem;
    }
  }

  &.BlobsModelo4 {
    display: block;
    position: absolute;

    width: 14.25rem;
    height: 11.25rem;
    transform: translate(48.5%, 29%) rotate(-5deg);

    
 

    @media ${device.laptop} {
      width: 20.313rem;
      height:16.063rem
      transform: translate(0%, -6%) rotate(-5deg);
    }

    @media ${device.tablet} {
      width: 20.313rem;
      height: 15.063rem;
      transform: translate(0%, -6%) rotate(-5deg);
    }

    @media ${device.mobile} {
      width: 8.875rem;
      height: 7.063rem;
      transform: translate(52%, 23%) rotate(0deg);
    }

    @media ${device.mobile375} {
      width: 7.938rem;
      height: 7.313rem;
      transform: translate(48%, 10%) rotate(0deg);
    }

    @media ${device.mobile320} {
      width: 6.5rem;
      height: 5.125rem;
      transform: translate(52%, 23%) rotate(0deg);
    }
  }

  &.BlobsModelo5 {
    display: block;
    position: absolute;

    width: 14.25rem;
    height: 11.25rem;
    transform: translate(48.5%, 29%) rotate(-5deg);
    @media ${device.laptop} {
      width: 20.313rem;
      height:15.063rem;
      transform: translate(0%, -6%) rotate(-5deg);
    }

    @media ${device.tablet} {
      width: 20.313rem;
      height: 15.063rem;
      transform: translate(0%, -6%) rotate(-5deg);
    }

    @media ${device.mobile} {
      width: 11.175rem;
      height: 10.463rem;
      transform: translate(52%, 23%) rotate(0deg);
    }

    @media ${device.mobile500} {
      width: 8.875rem;
      height: 8.263rem;
      transform: translate(52%, 23%) rotate(0deg);
    }


    @media ${device.mobile425} {
      width: 8.738rem;
      height: 9.313rem;
      transform: translate(48%, 10%) rotate(0deg);
    }

    @media ${device.mobile395} {
      width: 8.238rem;
      height: 8.513rem;
      transform: translate(48%, 10%) rotate(0deg);
    }


    @media ${device.mobile375} {
      width: 7.938rem;
      height: 8.313rem;
      transform: translate(48%, 10%) rotate(0deg);
    }
    @media ${device.mobile360} {
      width: 7.638rem;
      height: 7.713rem;
      transform: translate(48%, 10%) rotate(0deg);
    }

    @media ${device.mobile320} {
      width: 6.5rem;
      height: 5.125rem;
      transform: translate(52%, 23%) rotate(0deg);
    }

    @media ${device.mobile280} {
      width: 5.9rem;
      height: 4.9rem;
      transform: translate(52%, 23%) rotate(0deg);
    }
  }
`;

export const ImageBlobPurple = styled.div`
  display: none;

  background-image: ${({ bgUrl }) => `url(${bgUrl})`};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  &.defaultLayoutMedicalSpecialties {
    display: inline-block;
    position: absolute;
    top: -14.5rem;
    right: -19.5rem;
    width: 13.125rem;
    height: 11.875rem;
    transform: rotate(181deg);
    z-index: -1;

    @media ${device.laptop} {
      top: -13rem;
      right: -18.5rem;
      width: 7rem;
      height: 6.375rem;
    }

    @media ${device.mobile} {
      top: -8.625rem;
      right: -10.9375rem;
      width: 8rem;
      height: 8.375rem;
    }

    @media (max-width: 414px) and (min-width: 411px) {
      top: -9.5rem;
      right: -12.0625rem;
    }

    @media ${device.mobile360} {
      right: -10.3125rem;
    }

    @media ${device.mobile320} {
      top: -7.5rem;
      right: -9.0625rem;
    }
  }

  &.defaultLayoutMedicalSpecialtiesType2 {
    display: inline-block;
    position: absolute;
    top: -14.5rem;
    right: -19.5rem;
    width: 13.125rem;
    height: 11.875rem;
    transform: rotate(181deg);
    z-index: -1;

    @media ${device.laptop} {
      top: -13rem;
      right: -18.5rem;
      width: 7rem;
      height: 6.375rem;
    }

    @media ${device.mobile} {
      top: -8.625rem;
      right: -10.9375rem;
      width: 8rem;
      height: 8.375rem;
    }

    @media (max-width: 414px) and (min-width: 411px) {
      top: -9.5rem;
      right: -12.0625rem;
    }

    @media ${device.mobile360} {
      right: -10.3125rem;
    }

    @media ${device.mobile320} {
      top: -7.5rem;
      right: -9.0625rem;
    }
  }

  &.BlobsModelo1 {
    display: inline-block;
    position: absolute;
    top: -16rem;
    right: -19.5rem;
    width: 13.125rem;
    height: 11.875rem;
    transform: rotate(42.85deg);
    z-index: -1;

    @media ${device.laptop} {
      top: -14rem;
      right: -18.5rem;
      width: 7rem;
      height: 6.375rem;
    }

    @media ${device.mobile} {
      top: -8.75rem;
      right: -10.5rem;
      width: 7rem;
      height: 6.375rem;
    }

    @media ${device.mobile425} {
      top: -9.75rem;
      right: -10.9rem;
      width: 7rem;
      height: 6.375rem;
    }

    @media ${device.mobile375} {
      top: -8.75rem;
      right: -10.5rem;
      width: 7rem;
      height: 6.375rem;
    }

    @media ${device.mobile360} {
      top: -8rem;
      right: -9rem;
      width: 7rem;
      height: 6.375rem;
    }
  }

  &.BlobsModelo4 {
    display: inline-block;
    position: absolute;
    width: 14.25rem;
    height: 13.063rem;
    transform: translate(-141.5%, -118%) rotate(180deg);
    z-index: -1;

    @media ${device.laptop} {
      width: 25.313rem;
      height:  16.063rem
      transform: translate(-102%, -90%) rotate(180deg);
    }

    @media ${device.mobile} {
      width: 8.875rem;
      height: 7.063rem;
      transform: translate(-150%, -130%) rotate(179deg);
    }

    @media ${device.mobile425} {
      width: 8.848rem;
      height: 7.313rem;
      transform: translate(-140%, -110%) rotate(179deg);
    }
    @media ${device.mobile395} {
      width: 8.288rem;
      height: 7.313rem;
      transform: translate(-140%, -110%) rotate(179deg);
    }

    @media ${device.mobile375} {
      width: 7.638rem;
      height: 7.313rem;
      transform: translate(-140%, -110%) rotate(179deg);
    }

    @media ${device.mobile360} {
      width: 7.338rem;
      height: 7.313rem;
      transform: translate(-140%, -110%) rotate(179deg);
    }

    @media ${device.mobile320} {
      width: 6.625rem;
      height: 6.063rem;
      transform: translate(-138%, -112%) rotate(179deg);
    }
  }

  &.BlobsModelo5 {
    display: inline-block;
    position: absolute;
    width: 14.25rem;
    height: 12.269rem;
    margin-top:72px;
    transform: translate(-141.5%, -118%) rotate(180deg);
    z-index: -1;

    @media ${device.laptop} {
      width: 25.313rem;
      height: 12.556rem;
      transform: translate(-102%, -90%) rotate(180deg);
      margin-top: 15px;
    }

    @media ${device.mobile} {
      width: 11.875rem;
      height:7.963rem;
      transform: translate(-150%, -130%) rotate(179deg);
      margin-top: 67px;
    }

    @media ${device.mobile500} {
      width: 9.648rem;
      height: 7.313rem;
      transform: translate(-140%, -110%) rotate(179deg);
      margin-top: 40px;
    }

    @media ${device.mobile425} {
      width: 9.048rem;
      height: 7.313rem;
      transform: translate(-140%, -110%) rotate(179deg);
    }
    @media ${device.mobile395} {
      width: 8.288rem;
      height: 7.313rem;
      transform: translate(-140%, -110%) rotate(179deg);
    }

    @media ${device.mobile375} {
      width: 7.938rem;
      height: 7.913rem;
      transform: translate(-140%, -110%) rotate(179deg);
      margin-top: 42px;
    }

    @media ${device.mobile360} {
      width: 7.438rem;
      height: 7.313rem;
      transform: translate(-140%, -110%) rotate(179deg);
    }

    @media ${device.mobile320} {
      width: 6.625rem;
      height: 6.063rem;
      transform: translate(-138%, -112%) rotate(179deg);
    }

    @media ${device.mobile280} {
      width: 5.725rem;
      height: 6.063rem;
      transform: translate(-138%, -112%) rotate(179deg);
      margin-top:35px;
    }
  }
`;

export const ImageBlobMarineBlue = styled.div`
  display: none;

  background-image: ${({ bgUrl }) => `url(${bgUrl})`};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  &.BlobsModelo2 {
    display: inline-block;
    position: absolute;
    top: -8.5rem;
    left: -15rem;
    width: 19.75rem;
    height: 16.125rem;
    transform: rotate(20.68deg);
    z-index: -1;

    @media ${device.laptop} {
      top: -4rem;
      left: -11rem;
      width: 14.25rem;
      height: 11.625rem;
    }

    @media ${device.mobile} {
      top: -5rem;
      left: -9rem;
      width: 14.25rem;
      height: 11.625rem;
      max-width: unset;
    }

    @media ${device.laptop} {
      max-width: unset;
    }
  }

  &.BlobsModelo3 {
    display: inline-block;
    position: absolute;
    top: -8.5rem;
    left: -15rem;
    width: 19.75rem;
    height: 16.125rem;
    transform: rotate(20.68deg);
    z-index: -1;

    @media ${device.laptop} {
      top: -4rem;
      left: -11rem;
      width: 14.25rem;
      height: 11.625rem;
    }

    @media ${device.mobile} {
      top: -5rem;
      left: -9rem;
      width: 14.25rem;
      height: 11.625rem;
      max-width: unset;
    }

    @media ${device.laptop} {
      max-width: unset;
    }
  }
`;
