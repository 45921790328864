import React from 'react';
import { Link } from 'gatsby';

import { Container, BreadcrumbContent } from './style';

function Breadcrumb({ markdownContent = '', lightMode = false }) {
  const LinkRender = ({ href, children }) => {
    const isInternalLink = href.startsWith('/');

    if (isInternalLink) {
      return <Link to={href}>{children}</Link>;
    }

    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  };

  return (
    <Container>
      <BreadcrumbContent
        children={markdownContent ?? ''}
        renderers={{
          link: LinkRender,
        }}
        lightMode={lightMode}
      />
    </Container>
  );
}

export { Breadcrumb };
