import React from 'react';
import { Link } from 'gatsby';

import { Container, HighlightContent } from './style';

const Highlight = ({ markdownContent }) => {
  const LinkRender = ({ href, children }) => {
    const isInternalLink = href.startsWith('/');

    if (isInternalLink) {
      return <Link to={href}>{children}</Link>;
    }

    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  };

  return (
    <Container>
      <HighlightContent
        renderers={{
          link: LinkRender,
        }}
      >
        {markdownContent}
      </HighlightContent>
    </Container>
  );
};

export { Highlight };
