import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { device } from '../../../device';

export const Container = styled(ReactMarkdown)`
  margin-top: 6.1rem;
  margin-bottom: -3.2rem;
  z-index: 98;

  &.FeaturedMarketingModelo6 {
    margin-top: 6rem;
    margin-bottom: -8.5rem;

    @media ${device.laptop} {
      margin-top: 1.25rem;
      margin-bottom: 0rem;
    }
  }

  p:first-child {
    background: #45a7df;
    border-radius: 2rem;
    color: #fff;
    padding: 0.5rem 0rem;
    width: 5.5rem;
    text-align: center;
    margin-left: 1rem;
    margin-bottom: -0.8rem;
    z-index: 98;
    position: relative;
    font-size: 0.6rem;
  }

  a {
    display: flex;
    justify-content: space-between;
    background: #f4f5f7;
    border-radius: 0.75rem;
    color: #000;
    padding: 1rem;

    em {
      font-style: normal;
      width: 25rem;

      @media ${device.laptop} {
        width: 13rem;
      }
    }
  }

  @media ${device.laptop} {
    margin-top: 1.25rem;
    margin-bottom: -4rem;
  }
`;
