import React, { useEffect, useState } from 'react';

import * as S from './style';

import RenderMaps from 'components/units/home/maps';

const Maps = ({ markdownContent }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [scriptReady, setScript] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_API_KEY}&language=pt-BR`;
    script.addEventListener('load', () => {
      setScript(true);
    });
    document.body.appendChild(script);
  }, []);

  const latLng = {
    lat: markdownContent?.coordenadas.latitude,
    lng: markdownContent?.coordenadas.longitude,
  };

  const updateSize = () => {
    setIsMobile(window.innerWidth < 648);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateSize);
      setIsMobile(window.innerWidth < 648);
      return () => window.removeEventListener('resize', updateSize);
    }
  }, []);

  return (
    <>
      <S.MapsMobile mapImage={markdownContent?.assetpicker.url}>
        {isMobile && (
          <RenderMaps
            zoom={markdownContent?.zoom}
            defaultCenter={latLng}
            location={latLng}
            scriptReady={scriptReady}
          />
        )}
      </S.MapsMobile>
      <S.Maps mapImage={markdownContent?.assetpicker.url} />
    </>
  );
};

export { Maps };
